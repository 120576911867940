import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { doc, setDoc } from 'firebase/firestore'

import { Box, IconButton, Stack } from '@mui/material'
import { Clear, Send } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'

import { AddEmoji } from './AddEmoji'
import { IUser } from 'src/types/types'

export const AddPost: FC = () => {
  const { t } = useTranslation(['news'])
  const { users } = useAppSelector((state) => state.users)
  const { db } = useAuth()

  const { emoji, uid, displayName, photoURL, verified, oac } = useAppSelector(
    (state) => state.user
  )

  const profileId = window.location.pathname.replace('/livestream/', '')

  const user = users.find((user: IUser) => user.uid === profileId)

  const [content, setContent] = useState('')

  const handleSendPost = async (e: any) => {
    if (!content.replaceAll('ㅤ', '').trim()) return
    const uniqueId = generateUniqueId()

    try {
      await setDoc(doc(db, 'chats', uniqueId), {
        author: { uid, displayName, photoURL, emoji, verified, oac },
        content: content.replaceAll('ㅤ', '').trim(),
        createdAt: Date.now(),
        id: uniqueId,
        streamId: user?.uid
      })

      setContent('')
    } catch (e) {
      console.error('Error adding document: ', e)
    }
    setContent('')
    e.target.blur()
  }


  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Stack alignItems="center" direction="row">
        <ThemeTextFieldAddPost
          label={<b>{t(`Type in the chat...`)}</b>}
          multiline
          fullWidth
          autoComplete="off"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          sx={{ mx: 2 }}
        />
        <AddEmoji setContent={setContent} />
        <IconButton
          color="primary"
          onClick={handleSendPost}
          title={t('Post', { ns: ['other'] }) || ''}
          sx={{ width: '50px ', height: '50px', mx: -1 }}
        >
          <Send />
        </IconButton>
      </Stack>
    </BorderBox>
  )
}
