import { FC, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  setDoc,
  increment,
  getDocs,
  DocumentData,
} from 'firebase/firestore'

import { Box, Skeleton, Stack, Tooltip, Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material'
import { Flag, TaskAlt, Tune, Link, Backup, Audiotrack, Block, Cancel } from '@mui/icons-material'

import { useAppSelector, useAppDispatch } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ThemeSmallButton } from '@ui/ThemeButton'
import { ModalImage } from '@modals/ModalImage'
import { SkeletonPost } from '@ui/skeletons/SkeletonPost'
import { ModalFollowers } from '@modals/ModalFollowers'
import { ModalFollowing } from '@modals/ModalFollowing'

import { IPost, IUser } from 'src/types/types'
import { AddFriend } from './components/AddFriend'
import { FriendList } from './components/FriendList'
import { useSnackbar } from 'notistack'
import { TransitionGroup } from 'react-transition-group'
import { useHandleScroll } from '@hooks/useHandleScroll'
import { NewsOrderBy } from './components//NewsOrderBy'
import { setPosts } from '@reducers/PostsSlice'

import DiscordWebhook from 'discord-webhook-ts';
import { NewsPost } from './components/NewsPost'

export const Profile: FC = () => {
  const { t } = useTranslation(['profile'])
  const { db, usersRdb } = useAuth()
  const navigate = useNavigate()
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar()

  const { uid } = useAppSelector((state) => state.user)
  const { users } = useAppSelector((state) => state.users)
  const { posts } = useAppSelector((state) => state.posts)
  const dispatch = useAppDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [openModalFl, setOpenModalFl] = useState(false)
  const [modalData, setModalData] = useState<IUser[]>([])

  const usersRdbList = Object.values(usersRdb)
  const profileId = window.location.pathname.replace('/profile/', '')

  const user = users.find((user: IUser) => user.uid === profileId)
  const userPosts = posts.filter((post: IPost) => post.author.uid === profileId)

  document.title = user?.displayName || 'Talkie'

  const [openImage, setOpenImage] = useState(false)
  const [modalImage, setModalImage] = useState<string>('')

  const { numberVisiblePosts, setNumberVisiblePosts } = useHandleScroll(10, 10)

  const [editingId, setEditingId] = useState('')
  const [deletedPosts, setDeletedPosts] = useState<IPost[]>([])
  const [withPhoto, setWithPhoto] = useState(false)

  const discordClient = new DiscordWebhook('https://discord.com/api/webhooks/1276615980809982034/F29z3JSzHoodXwN4_B7L_ET1RaPiHwemVWTMFwI3adAUbzS-fcFnWhn1_cPP_v-PaARe');

  const handleFollowersOpenModal = (post: IUser) => {
    setOpenModal(true)
    setModalData(post.followers)
  }

  const handleFollowingOpenModal = (post: IUser) => {
    setOpenModalFl(true)
    setModalData(post.following)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setOpenModalFl(false)
    setModalData([])
  }

  const handleOpenImage = (image: string) => {
    if (!image) return
    setOpenImage(true)
    setModalImage(image)
  }

  const handleCloseImage = () => {
    setOpenImage(false)
    setModalImage('')
  }

  const handleSettings = () => {
    navigate('/profile/settings')
  }

  const handleReport = async (post: IUser) => {
    discordClient.execute({
      embeds: [
          {
              title: 'New User Report',
              description: "User: **" + post.displayName + "**",
              fields: [
                {
                  name: 'User ID',
                  value: post.uid,
                },
                {
                  name: 'Bio',
                  value: post.bio,
                },
            ],
            image: {
              url: post.photoURL,
            }
          },
      ]
  }).then((response) => {
      console.log('Successfully sent webhook.')
      enqueueSnackbar(t('Post reported!'), { variant: 'error' })
  })
  }

  useEffect(() => {
    const q = query(collection(db, 'posts'), orderBy('createdAt', 'desc'))

    const incViews = async () => {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(async (d) => {
        const docRef = doc(db, 'posts', d.id)
        await setDoc(docRef, { views: increment(1) }, { merge: true })
      })
    }

    const setPostsFunc = onSnapshot(q, (querySnapshot) => {
      const postsArr: IPost[] = []
      querySnapshot.forEach(async (d: DocumentData) => {
        postsArr.push(d.data())
      })

      if (withPhoto) {
        dispatch(setPosts(postsArr.filter((post) => post?.images?.length > 0)))
      } else {
        dispatch(setPosts(postsArr))
      }
    })

    return () => {
      incViews()
      setPostsFunc()
    }
    // eslint-disable-next-line
  }, [db, withPhoto])

  const banuser = async () => {
    if (!user) return
    const docRef = doc(db, 'users', user.uid)
    await setDoc(docRef, { banned: true }, { merge: true })
  }

  const unbanuser = async () => {
    if (!user) return
    const docRef = doc(db, 'users', user.uid)
    await setDoc(docRef, { banned: false }, { merge: true })
  }

  return (
    <>
      <BorderBox sx={{ p: 3, mb: 2 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          spacing={3}
          justifyContent="space-between"
          sx={{ ml: 2, mb: 2 }}
        >
          <Box>
            {usersRdbList.length > 0 && user?.banned === false && user?.uid ? (
              <ThemeProfileAvatar
                alt={user.displayName}
                src={user.photoURL}
                sx={{ cursor: user.photoURL ? 'pointer' : 'auto' }}
                draggable={false}
                onClick={() => handleOpenImage(user.photoURL)}
              >
                <Typography variant="h2">{user.emoji}</Typography>
              </ThemeProfileAvatar>
            ) : (
              <Skeleton
                variant="circular"
                sx={{ height: '150px', width: '150px' }}
              />
            )}
          </Box>
          <Stack spacing={3.5} sx={{ width: '100%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              spacing={2}
            >
              <Stack alignItems="center" direction="row" spacing={0.7}>
                <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
                  {user?.banned === false && usersRdbList.length > 0 ? (
                    <b>{user?.displayName}</b>
                  ) : (
                    <Skeleton width={250} />
                  )}
                </Typography>
                {usersRdbList.length > 0 &&
                  user?.verified === true && user?.banned === false && (
                    <Tooltip
                      title={t('Verified', { ns: ['other'] })}
                      placement="top"
                    >
                      <TaskAlt
                        color="info"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  {usersRdbList.length > 0 &&
                  user?.oac === true && user?.banned === false && (
                    <Tooltip
                      title={t('Official Artist Account', { ns: ['other'] })}
                      placement="top"
                    >
                      <Audiotrack
                        color="error"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  {usersRdbList.length > 0 &&
                  user?.affiliate === true && user?.banned === false && (
                    <Tooltip
                      title={t('Affiliate', { ns: ['other'] })}
                      placement="top"
                    >
                      <Backup
                        color="primary"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  
              </Stack>
              <Typography color="textSecondary">
                {usersRdbList.length > 0 &&
                user?.uid && user?.banned === false &&
                usersRdb[profileId]?.isOnline ? (
                  t('online', { ns: ['other'] })
                ) : usersRdbList.length > 0 &&
                  user?.uid && user?.banned === false &&
                  usersRdb[profileId]?.lastOnline ? (
                  `${t('last seen', { ns: ['other'] })} ${moment(
                    usersRdb[profileId]?.lastOnline
                  ).calendar()}`
                ) : usersRdbList.length > 0 &&
                  user?.uid && user?.banned === false &&
                  !usersRdb[profileId]?.isOnline &&
                  !usersRdb[profileId]?.lastOnline ? (
                  t('offline', { ns: ['other'] })
                ) : (
                  <Skeleton width={100} />
                )}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <Stack direction="row" spacing={2}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '60px' }}
                >
                  <Typography variant="h4" color="textSecondary" sx={{ cursor: 'pointer' }}>
                    {usersRdbList.length > 0 && user?.banned === false && user?.uid ?  (
                      <b onClick={() => handleFollowersOpenModal(user)}>{user.followers.length}</b>
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                  <Typography color="textSecondary">
                    {usersRdbList.length > 0 && user?.uid ? (
                      t('followers')
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                </Stack>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '60px' }}
                >
                  <Typography variant="h4" color="textSecondary" sx={{ cursor: 'pointer' }}>
                    {usersRdbList.length > 0 && user?.banned === false && user?.uid ?  (
                      <b onClick={() => handleFollowingOpenModal(user)}>{user.following.length}</b>
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                  <Typography color="textSecondary">
                    {usersRdbList.length > 0 && user?.uid ? (
                      t('following')
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                </Stack>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '60px' }}
                >
                  <Typography variant="h4" color="textSecondary">
                    {usersRdbList.length > 0 && user?.banned === false && user?.uid ? (
                      <b>{userPosts.length}</b>
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                  <Typography color="textSecondary">
                    {usersRdbList.length > 0 && user?.uid ? (
                      t('posts')
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </Typography>
                </Stack>
              </Stack>
              {usersRdbList.length > 0 && uid !== profileId && user?.uid && user?.banned === false && (
                <AddFriend />
              )}
              {usersRdbList.length > 0 && uid !== profileId && user?.uid && user?.banned === false && (
                <ThemeSmallButton startIcon={<Flag />} 
                onClick={() => handleReport(user)}>
                  <b>{t('Report User')}</b>
                </ThemeSmallButton>
              )}
              
              {usersRdbList.length > 0 && user?.uid && uid === profileId && user?.banned === false && (
                <ThemeSmallButton startIcon={<Tune />} onClick={handleSettings}>
                  <b>{t('Settings')}</b>
                </ThemeSmallButton>
              )}
            
            </Stack>
            {user?.banned === false &&
            <Typography
              sx={{
                ml: 1,
                wordBreak: 'break-word',
                whiteSpace: 'pre-line',
              }}
            >
              {user?.bio}
            </Typography>
            }
            {uid === "uwC8FkdFDCZ0s8nuHn1Xm09aJsu2" && user?.banned === false &&
            <ThemeSmallButton startIcon={<Block />} 
                onClick={() => banuser()}
                color='error'>
                  <b>Ban User</b>
            </ThemeSmallButton>
            }
            {uid === "uwC8FkdFDCZ0s8nuHn1Xm09aJsu2" && user?.banned === true &&
            <ThemeSmallButton startIcon={<Cancel />} 
                onClick={() => unbanuser()}
                color='primary'>
                  <b>Unban User</b>
            </ThemeSmallButton>
            }
          </Stack>
        </Stack>
      </BorderBox>
      <ModalImage
        openImage={openImage}
        handleCloseImage={handleCloseImage}
        modalImage={modalImage}
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ ml: 2, mb: 2 }}
      >
        <NewsOrderBy setNumberVisiblePosts={setNumberVisiblePosts} />
      </Stack>
      {users.length > 0 ? (
        <>
      <TransitionGroup>
            {posts.slice(0, numberVisiblePosts).map((post) => (
              <Collapse key={post.id}>
                {post.author.uid === user?.uid && user?.banned === false && (
                <NewsPost
                  post={post}
                  deletedPosts={deletedPosts}
                  setDeletedPosts={setDeletedPosts}
                  editingId={editingId}
                  setEditingId={setEditingId}
                />
                )}
              </Collapse>
            ))}
          </TransitionGroup>
          {numberVisiblePosts < posts.length && <SkeletonPost />}
          </>
      ) : (
        [...Array(10).keys()].map((post) => <SkeletonPost key={post} />)
      )}
      <ModalFollowers
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
      />
      <ModalFollowing
        openModal={openModalFl}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
      />
    </>
  )
}
