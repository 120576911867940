import React, { useState, useRef, FC, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause, FaExpand, FaCompress } from 'react-icons/fa';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { IUser } from 'src/types/types';
import Hls from 'hls.js';

type Props = {
    user: IUser
}

const VideoPlayer: FC<Props> = ({
    user
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(user.live);
        hls.attachMedia(video);

        // Check if the stream is live
        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          const isLiveStream = data.levels.some(level => level.details?.live);
          setIsLive(isLiveStream);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = user.live;
        // For HLS.js not supported, we can't check live status.
        // Assume it's live if this is the case.
        setIsLive(true);
      }
    }
  }, [user.live]);

  const togglePlayPause = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (isPlaying) {
        video.pause();
      } else {
        video.currentTime = video.duration; 
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (!isFullscreen) {
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } 
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px', margin: '0 auto', mt: 4 }} onMouseEnter={() => setShowControls(true)}
    onMouseLeave={() => setShowControls(false)}>

      {/* Video Element without default controls */}
      <Box sx={{ position: 'relative', width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
        <video ref={videoRef} controls={false} width="100%" style={{ borderRadius: '8px' }} />

        {/* Overlay Controls */}
         {showControls && isLive &&  (
          <Box
          sx={{
            position: 'absolute',
            bottom: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}
        >
            <IconButton 
              onClick={togglePlayPause} 
              color="primary" 
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', pointerEvents: 'auto', }}
            >
              {isPlaying ? <FaPause fontSize="large" style={{ color: 'white', fontSize: '3rem' }} /> : <FaPlay fontSize="large" style={{ color: 'white', fontSize: '3rem' }} />}
            </IconButton>
          </Box>
        )}
        {!isLive && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              color: 'red',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: 2,
              borderRadius: 4,
            }}
          >
            <Typography variant="h4">Stream is Offline!</Typography>
            <Typography variant="h6">Try refreshing the website.</Typography>
          </Box>
        )}
          </Box>
      </Box>
  );
};

export default VideoPlayer;