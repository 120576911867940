import { FC, useState, useEffect, SetStateAction } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  setDoc,
  increment,
  getDocs,
  DocumentData,
} from 'firebase/firestore'

import { Box, Skeleton, Stack, Tooltip, Typography, Collapse, Checkbox, FormControlLabel } from '@mui/material'
import { Flag, TaskAlt, Tune, Link, Backup, Audiotrack, Block, Cancel } from '@mui/icons-material'

import { useAppSelector, useAppDispatch } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ModalImage } from '@modals/ModalImage'
import { SkeletonPost } from '@ui/skeletons/SkeletonPost'
import { ModalFollowers } from '@modals/ModalFollowers'
import { ModalFollowing } from '@modals/ModalFollowing'

import { IChat, IPost, IUser } from 'src/types/types'
import { useSnackbar } from 'notistack'
import { TransitionGroup } from 'react-transition-group'
import { useHandleScroll } from '@hooks/useHandleScroll'
import { setPosts } from '@reducers/ChatsSlice'

import DiscordWebhook from 'discord-webhook-ts';
import { NewsPost } from './components/NewsPost'
import VideoPlayer from './components/VideoPlayer'
import { AddPost } from './components/AddPost'
import { AddFriend } from './components/AddFriend'
import { ThemeSmallButton } from '@ui/ThemeButton'

export const Livestream: FC = () => {
  const { t } = useTranslation(['profile'])
  const { db, usersRdb } = useAuth()
  const navigate = useNavigate()
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar()

  const { uid } = useAppSelector((state) => state.user)
  const { users } = useAppSelector((state) => state.users)
  const { posts } = useAppSelector((state) => state.chat)
  const dispatch = useAppDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [openModalFl, setOpenModalFl] = useState(false)
  const [modalData, setModalData] = useState<IUser[]>([])

  const usersRdbList = Object.values(usersRdb)
  const profileId = window.location.pathname.replace('/livestream/', '')

  const user = users.find((user: IUser) => user.uid === profileId)

  document.title = user?.displayName || 'Talkie'

  const [openImage, setOpenImage] = useState(false)
  const [modalImage, setModalImage] = useState<string>('')

  const { numberVisiblePosts, setNumberVisiblePosts } = useHandleScroll(10, 10)

  const [editingId, setEditingId] = useState('')
  const [deletedPosts, setDeletedPosts] = useState<IPost[]>([])
  const [withPhoto, setWithPhoto] = useState(false)

  const discordClient = new DiscordWebhook('https://discord.com/api/webhooks/1276615980809982034/F29z3JSzHoodXwN4_B7L_ET1RaPiHwemVWTMFwI3adAUbzS-fcFnWhn1_cPP_v-PaARe');

  const handleFollowersOpenModal = (post: IUser) => {
    setOpenModal(true)
    setModalData(post.followers)
  }

  const handleFollowingOpenModal = (post: IUser) => {
    setOpenModalFl(true)
    setModalData(post.following)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setOpenModalFl(false)
    setModalData([])
  }

  const handleOpenImage = (image: string) => {
    if (!image) return
    setOpenImage(true)
    setModalImage(image)
  }

  const handleCloseImage = () => {
    setOpenImage(false)
    setModalImage('')
  }

  const handleSettings = () => {
    navigate('/profile/settings')
  }

  const handleReport = async (post: IUser) => {
    discordClient.execute({
      embeds: [
          {
              title: 'New User Report',
              description: "User: **" + post.displayName + "**",
              fields: [
                {
                  name: 'User ID',
                  value: post.uid,
                },
                {
                  name: 'Bio',
                  value: post.bio,
                },
            ],
            image: {
              url: post.photoURL,
            }
          },
      ]
  }).then((response) => {
      console.log('Successfully sent webhook.')
      enqueueSnackbar(t('Post reported!'), { variant: 'error' })
  })
  }

  useEffect(() => {
    const q = query(collection(db, 'chats'), orderBy('createdAt', 'desc'))

    const setPostsFunc = onSnapshot(q, (querySnapshot) => {
      const postsArr: IChat[] = []
      querySnapshot.forEach(async (d: DocumentData) => {
        postsArr.push(d.data())
      })

      dispatch(setPosts(postsArr))
    })

    return () => {
      setPostsFunc()
    }
    // eslint-disable-next-line
  }, [db])


  return (
    <>
      <BorderBox sx={{ p: 3, mb: 2 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          spacing={3}
          justifyContent="space-between"
          sx={{ ml: 2, mb: 2 }}
        >
          <Box>
            {usersRdbList.length > 0 && user?.banned === false && user?.uid ? (
              <ThemeProfileAvatar
                alt={user.displayName}
                src={user.photoURL}
                sx={{ height: '70px', width: '70px', cursor: user.photoURL ? 'pointer' : 'auto' }}
                draggable={false}
                onClick={() => handleOpenImage(user.photoURL)}
              >
                <Typography variant="h5">{user.emoji}</Typography>
              </ThemeProfileAvatar>
            ) : (
              <Skeleton
                variant="circular"
                sx={{ height: '50px', width: '50px' }}
              />
            )}
          </Box>
          <Stack spacing={3.5} sx={{ width: '100%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              spacing={2}
            >
              <Stack alignItems="center" direction="row" spacing={0.7}>
                <Typography variant="h5" sx={{ wordBreak: 'break-word' }}>
                  {user?.banned === false && usersRdbList.length > 0 ? (
                    <b>{user?.displayName}</b>
                  ) : (
                    <Skeleton width={250} />
                  )}
                </Typography>
                {usersRdbList.length > 0 &&
                  user?.verified === true && user?.banned === false && (
                    <Tooltip
                      title={t('Verified', { ns: ['other'] })}
                      placement="top"
                    >
                      <TaskAlt
                        color="info"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  {usersRdbList.length > 0 &&
                  user?.oac === true && user?.banned === false && (
                    <Tooltip
                      title={t('Official Artist Account', { ns: ['other'] })}
                      placement="top"
                    >
                      <Audiotrack
                        color="error"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  {usersRdbList.length > 0 &&
                  user?.affiliate === true && user?.banned === false && (
                    <Tooltip
                      title={t('Affiliate', { ns: ['other'] })}
                      placement="top"
                    >
                      <Backup
                        color="primary"
                        sx={{ width: '30px ', height: '30px' }}
                      />
                    </Tooltip>
                  )}
                  
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {user?.live ? (
            <VideoPlayer user={user}/>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              color: 'red',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: 2,
              borderRadius: 4,
            }}
          >
            <Typography variant="h4">Stream not Found!</Typography>
            <Typography variant="h6">This user doesn't have streams enabled.</Typography>
          </Box>
        )}
      </BorderBox>
      <ModalImage
        openImage={openImage}
        handleCloseImage={handleCloseImage}
        modalImage={modalImage}
      />
        <AddPost />
      {users.length > 0 ? (
        <>
      <TransitionGroup>
            {posts.slice(0, numberVisiblePosts).map((post) => (
              <Collapse key={post.id}>
                {post.streamId === user?.uid &&
                  <NewsPost
                    post={post}
                  />
                }
              </Collapse>
            ))}
          </TransitionGroup>
          {numberVisiblePosts < posts.length && <SkeletonPost />}
          </>
      ) : (
        [...Array(10).keys()].map((post) => <SkeletonPost key={post} />)
      )}
      <ModalFollowers
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
      />
      <ModalFollowing
        openModal={openModalFl}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
      />
    </>
  )
}
