import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'

import { store } from '../store/store'

import translationEnAbout from '@assets/translation/en/about.json'
import translationEnAuth from '@assets/translation/en/auth.json'
import translationEnBookmarks from '@assets/translation/en/bookmarks.json'
import translationEnFriends from '@assets/translation/en/friends.json'
import translationEnUsers from '@assets/translation/en/users.json'
import translationEnNews from '@assets/translation/en/news.json'
import translationEnNotFound from '@assets/translation/en/notFound.json'
import translationEnProfile from '@assets/translation/en/profile.json'
import translationEnProfileSettings from '@assets/translation/en/profilesettings.json'
import translationEnMenu from '@assets/translation/en/menu.json'
import translationEnOther from '@assets/translation/en/other.json'
import translationEnEmojiPicker from '@assets/translation/en/emojiPicker.json'
import translationEnAffiliate from '@assets/translation/en/affiliate.json'
import translationEnBanned from '@assets/translation/en/banned.json'
import translationEnVerified from '@assets/translation/en/verified.json'
import translationEnOaa from '@assets/translation/en/oaa.json'

import translationCzAbout from '@assets/translation/cz/about.json'
import translationCzAuth from '@assets/translation/cz/auth.json'
import translationCzBookmarks from '@assets/translation/cz/bookmarks.json'
import translationCzFriends from '@assets/translation/cz/friends.json'
import translationCzUsers from '@assets/translation/cz/users.json'
import translationCzNews from '@assets/translation/cz/news.json'
import translationCzNotFound from '@assets/translation/cz/notFound.json'
import translationCzProfile from '@assets/translation/cz/profile.json'
import translationCzProfileSettings from '@assets/translation/cz/profilesettings.json'
import translationCzMenu from '@assets/translation/cz/menu.json'
import translationCzOther from '@assets/translation/cz/other.json'
import translationCzEmojiPicker from '@assets/translation/cz/emojiPicker.json'
import translationCzAffiliate from '@assets/translation/cz/affiliate.json'
import translationCzBanned from '@assets/translation/cz/banned.json'
import translationCzVerified from '@assets/translation/cz/verified.json'
import translationCzOaa from '@assets/translation/cz/oaa.json'

import translationDeAbout from '@assets/translation/de/about.json'
import translationDeAuth from '@assets/translation/de/auth.json'
import translationDeBookmarks from '@assets/translation/de/bookmarks.json'
import translationDeFriends from '@assets/translation/de/friends.json'
import translationDeUsers from '@assets/translation/de/users.json'
import translationDeNews from '@assets/translation/de/news.json'
import translationDeNotFound from '@assets/translation/de/notFound.json'
import translationDeProfile from '@assets/translation/de/profile.json'
import translationDeProfileSettings from '@assets/translation/de/profilesettings.json'
import translationDeMenu from '@assets/translation/de/menu.json'
import translationDeOther from '@assets/translation/de/other.json'
import translationDeEmojiPicker from '@assets/translation/de/emojiPicker.json'
import translationDeAffiliate from '@assets/translation/de/affiliate.json'
import translationDeBanned from '@assets/translation/de/banned.json'
import translationDeVerified from '@assets/translation/de/verified.json'
import translationDeOaa from '@assets/translation/de/oaa.json'

import translationPlAbout from '@assets/translation/pl/about.json'
import translationPlAuth from '@assets/translation/pl/auth.json'
import translationPlBookmarks from '@assets/translation/pl/bookmarks.json'
import translationPlFriends from '@assets/translation/pl/friends.json'
import translationPlUsers from '@assets/translation/pl/users.json'
import translationPlNews from '@assets/translation/pl/news.json'
import translationPlNotFound from '@assets/translation/pl/notFound.json'
import translationPlProfile from '@assets/translation/pl/profile.json'
import translationPlProfileSettings from '@assets/translation/pl/profilesettings.json'
import translationPlMenu from '@assets/translation/pl/menu.json'
import translationPlOther from '@assets/translation/pl/other.json'
import translationPlEmojiPicker from '@assets/translation/pl/emojiPicker.json'
import translationPlAffiliate from '@assets/translation/pl/affiliate.json'
import translationPlBanned from '@assets/translation/pl/banned.json'
import translationPlVerified from '@assets/translation/pl/verified.json'
import translationPlOaa from '@assets/translation/pl/oaa.json'
import { verify } from 'crypto'


const resources = {
  en: {
    about: translationEnAbout,
    auth: translationEnAuth,
    bookmarks: translationEnBookmarks,
    friends: translationEnFriends,
    users: translationEnUsers,
    news: translationEnNews,
    notFound: translationEnNotFound,
    profile: translationEnProfile,
    settings: translationEnProfileSettings,
    menu: translationEnMenu,
    other: translationEnOther,
    emojiPicker: translationEnEmojiPicker,
    affiliate: translationEnAffiliate,
    banned: translationEnBanned,
    verified: translationEnVerified,
    oaa: translationEnOaa,
  },
  cz: {
    about: translationCzAbout,
    auth: translationCzAuth,
    bookmarks: translationCzBookmarks,
    friends: translationCzFriends,
    users: translationCzUsers,
    news: translationCzNews,
    notFound: translationCzNotFound,
    profile: translationCzProfile,
    settings: translationCzProfileSettings,
    menu: translationCzMenu,
    other: translationCzOther,
    emojiPicker: translationCzEmojiPicker,
    affiliate: translationCzAffiliate,
    banned: translationCzBanned,
    verified: translationCzVerified,
    oaa: translationCzOaa,
  },
  de: {
    about: translationDeAbout,
    auth: translationDeAuth,
    bookmarks: translationDeBookmarks,
    friends: translationDeFriends,
    users: translationDeUsers,
    news: translationDeNews,
    notFound: translationDeNotFound,
    profile: translationDeProfile,
    settings: translationDeProfileSettings,
    menu: translationDeMenu,
    other: translationDeOther,
    emojiPicker: translationDeEmojiPicker,
    affiliate: translationDeAffiliate,
    banned: translationDeBanned,
    verified: translationDeVerified,
    oaa: translationDeOaa,
  },
  pl: {
    about: translationPlAbout,
    auth: translationPlAuth,
    bookmarks: translationPlBookmarks,
    friends: translationPlFriends,
    users: translationPlUsers,
    news: translationPlNews,
    notFound: translationPlNotFound,
    profile: translationPlProfile,
    settings: translationPlProfileSettings,
    menu: translationPlMenu,
    other: translationPlOther,
    emojiPicker: translationPlEmojiPicker,
    affiliate: translationPlAffiliate,
    banned: translationPlBanned,
    verified: translationPlVerified,
    oaa: translationPlOaa,
  },
}

export const initI18next = () => {
  const lng = store.getState().global.language

  return i18next.use(initReactI18next).init({
    resources,
    lng,
  })
}
