export const deLocaleSpec = {
  monthsShort: 'Jan_Feb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Nov_Dez'.split('_'),
  calendar: {
    lastDay: '[gestern um] H:mm',
    sameDay: '[heute um] H:mm',
    nextDay: '[morgen um] H:mm',
    lastWeek: 'D MMM [um] H:mm',
    nextWeek: 'D MMM [um] H:mm',
    sameElse: 'D MMM YYYY',
  },
}
