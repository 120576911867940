import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Typography } from '@mui/material'

import { BackgroundPaperBox, BorderBox } from '@ui/ThemeBox'

export const PrivacyPolicy: FC = () => {
  const { t } = useTranslation(['notFound'])
  document.title = t('Privacy Policy')

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 5,
      }}
    >
    
        <Typography variant="h4" align="center">
          <b>{t('Privacy Policy')}</b>
        </Typography>
        <br />
        <Typography variant="subtitle1" align="center">
          {t(`Talkie ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service ("Service"). Please read this policy carefully.`)}
        </Typography>
        <br />
        <Typography variant="body1" align='center'>
        <h3><b>1. Information We Collect</b></h3>

        <h4><b>We collect several types of information from and about users of our Service, including:</b></h4>

        <b>a. Personal Information</b><br />
When you register an account, we may collect personal information that can identify you, such as your name, email address, and profile photo. You may also provide other personal information when using the Service.
<br /> <br />
<b>b. Non-Personal Information</b><br />
We may collect non-personal information, such as browser type, operating system, IP address, and device identifiers, which cannot be used to identify you personally.
<br /> <br />
<b>c. User Content</b><br />
Any content you upload or post to the Service, including messages, photos, and comments, may be collected and stored by us.
<br /> <br />
<b>d. Usage Data</b><br />
We collect information about how you interact with our Service, including the pages you visit, features you use, and the actions you take.<br/>

<h3><b>2. How We Use Your Information</b></h3>

<b>We use the information we collect for various purposes, including:</b><br />

- To provide, operate, and maintain the Service.<br />
- To improve, personalize, and expand the Service.<br />
- To communicate with you, including sending updates and promotional materials.<br />
- To monitor and analyze usage and trends to improve the user experience.<br />
- To protect the security and integrity of our Service.<br />

<h3><b>3. Sharing Your Information</b></h3>

<h4><b>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following situations:</b></h4>

<b>a. With Service Providers</b><br />
We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support.
<br /> <br />
<b>b. Legal Obligations</b><br />
We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order.
<br /> <br />
<b>c. Business Transfers</b><br />
In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.

<h3><b>4. Your Choices</b></h3>

<b>a. Access and Update Information</b><br />
You can access and update your personal information through your account settings. You are responsible for keeping your information accurate and up to date.
<br /> <br />
<b>b. Communication Preferences</b><br />
You may opt out of receiving promotional emails from us by following the unsubscribe instructions included in those emails. However, we may still send you non-promotional communications, such as service updates.
<br /> <br />
<b>c. Data Retention</b><br />
We will retain your personal information for as long as your account is active or as needed to provide you with the Service. We may also retain and use your information to comply with legal obligations, resolve disputes, and enforce our agreements.

<h3><b>5. Security</b></h3>

We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free, so please take care in deciding what information you send us.

<h3><b>6. Children's Privacy</b></h3>

Our Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete that information.

<h3><b>7. International Data Transfers</b></h3>

If you are accessing our Service from outside [Your Jurisdiction], your information may be transferred to, stored, and processed in a different country. By using our Service, you consent to such data transfers.

<h3><b>8. Third-Party Links</b></h3>

Our Service may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third parties. We encourage you to review their privacy policies.

<h3><b>9. Changes to This Privacy Policy</b></h3>

We may update this Privacy Policy from time to time. If we make material changes, we will notify you through the Service or by other means. Your continued use of the Service after the changes become effective constitutes your acceptance of the new Privacy Policy.

<h3><b>10. Contact Us</b></h3>

If you have any questions or concerns about this Privacy Policy, please contact us at support@talkie.lol.
<br />
---
<br />
By using our Service, you acknowledge that you have read and understand this Privacy Policy and agree to our collection, use, and disclosure of your information as described here.

</Typography>
      

    </Box>
    </BorderBox>
  )
}
