import React, { useState, useRef, FC } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay } from 'react-icons/fa'; // You can install react-icons if not installed: npm install react-icons
import { IPost } from 'src/types/types';

type Props = {
    post: IPost
}

const VideoPlayer: FC<Props> = ({
    post
}) => {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleEnded = () => {
    setPlaying(false); // Stop the video when it ends
  };

  return (
    <div style={{ position: 'relative', width: '100%', alignItems: "center"}}>
      <div
        onClick={handlePlayPause}
        style={{ cursor: 'pointer', position: 'relative', borderRadius: '5px' }}
      >
        <ReactPlayer
          ref={playerRef}
          url={post.video} // Example URL
          playing={playing}
          width="100%"
          height="50%"
          onEnded={handleEnded}
        />
        {!playing && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              padding: '20px',
            }}
          >
            <FaPlay style={{ color: 'primary', fontSize: '50px' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;