import { createSlice } from '@reduxjs/toolkit'

import { IGlobalState } from 'src/types/types'

const preferredLanguage = 'en'
const preferredTheme = window.matchMedia('(prefers-color-scheme: light)')
  .matches
  ? 'light'
  : 'dark'

const initialState = {
  language: preferredLanguage,
  theme: preferredTheme,
  format: 'block',
} as IGlobalState

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {

    setLangEN(state) {
      state.language = 'en'
    },

    setLangCZ(state) {
      state.language = 'cz'
    },

    setLangDE(state) {
      state.language = 'de'
    },

    setLangPL(state) {
      state.language = 'pl'
    },

    setThemeLight(state) {
      state.theme = 'light'
    },

    setThemeDark(state) {
      state.theme = 'dark'
    },

    setFormatBlock(state) {
      state.format = 'block'
    },

    setFormatList(state) {
      state.format = 'list'
    },
  },
})

export const {
  setLangEN,
  setLangCZ,
  setLangDE,
  setLangPL,
  setThemeLight,
  setThemeDark,
  setFormatBlock,
  setFormatList,
} = globalSlice.actions

export const globalReducer = globalSlice.reducer
