import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Link, Typography } from '@mui/material'
import { Paid, PersonAddAlt1 } from '@mui/icons-material'

import logo from '@assets/images/logo7.png'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeButton, ThemeSmallButton } from '@ui/ThemeButton'

import { builtWithList } from './builtWithList'

import { IUser } from 'src/types/types'
import { useAppSelector } from '@hooks/redux'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { useAuth } from '@hooks/useAuth'


type Props = {
  user: IUser | undefined
}


export const Banned: FC = () => {
  const { t } = useTranslation(['banned'])
  document.title = t("Banned")
  const { db } = useAuth()
  const { uid } = useAppSelector((state) => state.user)
  const { users } = useAppSelector((state) => state.users)
  const profileId = window.location.pathname.replace('/profile/', '')
  const user = users.find((user: IUser) => user.uid === uid)

  const joinaffiliate = async () => {
    if (!uid) return
    const docRef = doc(db, 'users', uid)
    await setDoc(docRef, { affiliate: true }, { merge: true })
  }

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="primary"
        textAlign="center"
      >
        {t('YOU HAVE BEEN BANNED')}
      </Typography>
      <Typography>{t('content1')} <br/><br/> {t('content4')} <Link color='primary' href='mailto:support@talkie.lol'>support@talkie.lol</Link>{t('content2')} <br/><br/> {t('content5')}</Typography>
      <ul>
        {builtWithList.map((item) => (
            <li>{t(item.title)}</li>
        ))}
      </ul>

      <Typography>{t('content3')} <br/><br/> {t('content6')}</Typography>
    </BorderBox>
  )
}

