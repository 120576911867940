import { FC, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material'
import { Logout, Tune, Person, Assignment, LockPerson, Language } from '@mui/icons-material'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { MenuBox } from '@ui/ThemeBox'
import { IUser } from 'src/types/types'
import { setLangEN, setLangCZ, setLangDE, setLangPL } from '@reducers/GlobalSlice'
import { enLocaleSpec } from '@assets/locales/en'
import { czLocaleSpec } from '@assets/locales/cz'
import { plLocaleSpec } from '@assets/locales/pl'
import { deLocaleSpec } from '@assets/locales/de'
import moment from 'moment'

export const LanguageMenu: FC = () => {
  const { t } = useTranslation(['menu'])
  const { rdb, ga } = useAuth()
  const navigate = useNavigate()

  const { uid, displayName, photoURL, emoji } = useAppSelector(
    (state) => state.user
  )
  const { i18n } = useTranslation()

  const { language } = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()

  useEffect(() => {
    i18n.changeLanguage(language)

    language === 'en'
      ? moment.updateLocale('en', enLocaleSpec)
      : language === 'de'
      ? moment.updateLocale('de', deLocaleSpec)
      : language === 'pl'
      ? moment.updateLocale('pl', plLocaleSpec)
      : language === 'cz'
      && moment.updateLocale('cz', czLocaleSpec)
    // eslint-disable-next-line
  }, [language])

  const { users } = useAppSelector((state) => state.users)

  const user = users.find((user: IUser) => user.uid === uid)

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  function setEN() {
    dispatch(setLangEN())
  }

  function setDE() {
    dispatch(setLangDE())
  }
  function setPL() {
    dispatch(setLangPL())
  }
  function setCZ() {
    dispatch(setLangCZ())
  }

  return (
    <Box
      onMouseOver={handleToggle}
      onMouseOut={handleToggle}
      sx={{ display: { md: 'block' } }}
    >
      <Box
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <IconButton
          color="primary"
          size="large"
          title={t('Language', { ns: ['other'] }) || ''}
          sx={{ width: '50px ', height: '50px' }}
        >
          <Language/>
          </IconButton>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 10, pt: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <MenuBox>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={setEN}
                  >
                    <ListItemIcon sx={{ ml: -0.5, mr: -0.5 }}>
                      <Typography color="primary">
                        <b>EN</b>
                      </Typography>
                    </ListItemIcon>
                    <Typography>English</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={setDE}
                  >
                    <ListItemIcon sx={{ ml: -0.5, mr: -0.5 }}>
                      <Typography color="primary">
                        <b>DE</b>
                      </Typography>
                    </ListItemIcon>
                    <Typography>Deutsch</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={setPL}
                  >
                    <ListItemIcon sx={{ ml: -0.5, mr: -0.5 }}>
                      <Typography color="primary">
                        <b>PL</b>
                      </Typography>
                    </ListItemIcon>
                    <Typography>Polski</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={setCZ}
                  >
                    <ListItemIcon sx={{ ml: -0.5, mr: -0.5 }}>
                      <Typography color="primary">
                        <b>CZ</b>
                      </Typography>
                    </ListItemIcon>
                    <Typography>Čeština</Typography>
                  </MenuItem>
                  <MenuItem>
                  <Typography color="error">
                    More soon...
                  </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </MenuBox>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}


