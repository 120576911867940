import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'
import { doc, runTransaction } from 'firebase/firestore'
import moment from 'moment'

import {
  alpha,
  AvatarGroup,
  Box,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Audiotrack,
  Backup,
  ChatBubbleOutline,
  Clear,
  Edit,
  Favorite,
  FavoriteBorder,
  TaskAlt,
  Visibility,
} from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { isOneDayPassed } from '@utils/isOneDayPassed'
import { showViews } from '@utils/showViews'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeAvatar, ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ThemeOnlineBadge } from '@ui/ThemeOnlineBadge'
import { ThemeTooltip } from '@ui/ThemeTooltip'
import { ModalLikes } from '@modals/ModalLikes'
import { ModalImage } from '@modals/ModalImage'

import { IComment, IChat, IUser } from 'src/types/types'
import { EditPost } from './EditPost'
import { DeletePost } from './DeletePost'
import { EditComment } from './EditComment'
import { AddComment } from './AddComment'

type Props = {
  post: IChat
}

export const NewsPost: FC<Props> = ({
  post,
}) => {
  const { t } = useTranslation(['news'])
  const { db, usersRdb } = useAuth()
  const theme = useTheme()

  const { emoji, uid, displayName, photoURL } = useAppSelector(
    (state) => state.user
  )
  const { users } = useAppSelector((state) => state.users)
  const { posts } = useAppSelector((state) => state.posts)

  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState<IUser[]>([])

  const [openImage, setOpenImage] = useState(false)
  const [modalImage, setModalImage] = useState<string>('')

  const [visibleId, setVisibleId] = useState<string>('')

  const handleOpenModalComments = (comment: IComment | undefined) => {
    if (!comment) return
    setOpenModal(true)
    setModalData(comment.likes)
  }

  const handleShow = (comment: IComment) => {
    setVisibleId(comment.id)
  }

  const handleHide = () => setVisibleId('')


  function getCommentAuthor(comment: IComment) {
    return users.find((user: IUser) => comment.author.uid === user.uid)
  }

  function getPostAuthor(post: IChat) {
    return users.find((user: IUser) => post.author.uid === user.uid)
  }

  return (
    <>
      <BorderBox
        sx={{
          p: 3,
          mb: 2,
          borderColor: '',
        }}
      >
          <Stack direction="row" justifyContent="space-between">
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <ThemeTooltip
                title={
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'center', sm: 'flex-start' }}
                    spacing={3}
                    sx={{ p: 3 }}
                  >
                    <ThemeProfileAvatar
                      alt={post.author.displayName}
                      src={
                        users.find((u) => u.uid === post.author.uid)?.photoURL
                      }
                      draggable={false}
                    >
                      <Typography variant="h2">{post.author.emoji}</Typography>
                    </ThemeProfileAvatar>
                    <Stack spacing={3.5}>
                      <Stack alignItems="center" direction="row" spacing={0.7}>
                        <Typography variant="h4">
                          <b>
                            {
                              users.find((u) => u.uid === post.author.uid)
                                ?.displayName
                            }
                          </b>
                          {users.find((u) => u.uid === post.author.uid)
                                  ?.verified ===
                          true && (
                          <TaskAlt
                            color="info"
                            sx={{ width: '30px ', height: '30px' }}
                          />
                        )}
                        {users.find((u) => u.uid === post.author.uid)
                                  ?.oac ===
                          true && (
                          <Audiotrack
                            color="error"
                            sx={{ width: '30px ', height: '30px' }}
                          />
                        )}
                        {users.find((u) => u.uid === post.author.uid)
                                  ?.affiliate ===
                          true && (
                          <Backup
                            color="primary"
                            sx={{ width: '30px ', height: '30px' }}
                          />
                        )}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: '55px' }}
                        >
                          <Typography variant="h4" color="textSecondary">
                            <b>
                              {
                                users.find((u) => u.uid === post.author.uid)
                                  ?.followers?.length
                              }
                            </b>
                          </Typography>
                          <Typography color="textSecondary">
                            {t('followers', { ns: ['profile'] })}
                          </Typography>
                        </Stack>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: '55px' }}
                        >
                          <Typography variant="h4" color="textSecondary">
                            <b>
                              {
                                users.find((u) => u.uid === post.author.uid)
                                  ?.following?.length
                              }
                            </b>
                          </Typography>
                          <Typography color="textSecondary">
                            {t('following', { ns: ['profile'] })}
                          </Typography>
                        </Stack>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: '55px' }}
                        >
                          <Typography color="textSecondary">
                            {t('posts', { ns: ['profile'] })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                }
                placement="top"
                followCursor
              >
                <Link to={`/profile/${post.author.uid}`}>
                  <ThemeOnlineBadge
                    overlap="circular"
                    variant={
                      usersRdb[post.author.uid]?.isOnline ? 'dot' : undefined
                    }
                  >
                    <ThemeAvatar
                      alt={post.author.displayName}
                      src={
                        users.find((u) => u.uid === post.author.uid)?.photoURL
                      }
                      draggable={false}
                    >
                      {post.author.emoji}
                    </ThemeAvatar>
                  </ThemeOnlineBadge>
                </Link>
              </ThemeTooltip>
              <Stack>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <Link to={`/profile/${post.author.uid}`}>
                    <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                      <b>
                        {
                          users.find((u) => u.uid === post.author.uid)
                            ?.displayName
                        }
                      </b>
                    </Typography>
                  </Link>
                  {getPostAuthor(post)?.verified === true  && (
                    <Tooltip
                      title={t('Verified', { ns: ['other'] })}
                      placement="top"
                    >
                      <TaskAlt
                        color="info"
                        sx={{ width: '20px ', height: '20px' }}
                      />
                    </Tooltip>
                  )}
                  {getPostAuthor(post)?.oac === true  && (
                    <Tooltip
                    title={t('Official Artist Account', { ns: ['other'] })}
                    placement="top"
                  >
                    <Audiotrack
                      color="error"
                      sx={{ width: '20px ', height: '20px' }}
                    />
                  </Tooltip>
                  )}
                </Stack>
                <Typography variant="body2" color="textSecondary">
                  {moment(post.createdAt).calendar()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <>
            <Typography
              sx={{
                ml: 1,
                wordBreak: 'break-word',
                whiteSpace: 'pre-line',
              }}
            >
              {post.content}
            </Typography>
          </>
      </BorderBox>
    </>
  )
}
