export const czLocaleSpec = {
  monthsShort: 'ledna_února_března_dubna_května_června_července_srpna_zaří_října_listopadu_prosince'.split('_'),
  calendar: {
    lastDay: '[včera v] H:mm',
    sameDay: '[dnes v] H:mm',
    nextDay: '[zítra v] H:mm',
    lastWeek: 'D MMM [v] H:mm',
    nextWeek: 'D MMM [v] H:mm',
    sameElse: 'D MMM YYYY',
  },
}
