import * as Icons from '@mui/icons-material'

import { ISocialsItem } from 'src/types/types'

export const socials: ISocialsItem[] = [
  {
    link: 'https://www.youtube.com/@redmoonko',
    icon: Icons.YouTube,
  },
  {
    link: 'https://www.instagram.com/redmoonko',
    icon: Icons.Instagram,
  },
]
