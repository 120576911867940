import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IChat, IChatsState } from 'src/types/types'

const initialState = {
  sortPostsBy: 'newest',
  posts: [],
} as IChatsState

export const chatsSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setPosts(state, action: PayloadAction<IChat[]>) {
      if (state.sortPostsBy === 'newest') {
        state.posts = action.payload.sort((a, b) => +b.createdAt - +a.createdAt)
      }
    },

    removePosts(state) {
      state.posts = []
    },

    setPostsByNewest(state) {
      state.sortPostsBy = 'newest'
      state.posts = state.posts.sort((a, b) => +b.createdAt - +a.createdAt)
    },

  },
})

export const {
  setPosts,
  removePosts,
  setPostsByNewest,
} = chatsSlice.actions

export const chatsReducer = chatsSlice.reducer
