import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { doc, setDoc } from 'firebase/firestore'
import { useSnackbar } from 'notistack'

import { Box, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import { Audiotrack, Check, Clear, Link, OpenInBrowser, Send, TaskAlt } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { moveFocusAtEnd } from '@utils/moveFocusAtEnd'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'
import { ThemeSmallButton } from '@ui/ThemeButton'
import logo from '@assets/images/logo7.png'

import { PhotoMenu } from './components/PhotoMenu'
import DiscordWebhook from 'discord-webhook-ts'
import { IUser } from 'src/types/types'

export const Oaa: FC = () => {
  const { t } = useTranslation(['oaa'])
  const { db, ga } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  document.title = t('Official Artist Account')

  const { uid } = useAppSelector((state) => state.user)

  const { users } = useAppSelector((state) => state.users)

  const user = users.find((user: IUser) => user.uid === uid)



  return (
    <BorderBox sx={{ p: 3, mb: 2  }}>
      <Stack alignItems="center" spacing={2}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="info"
        textAlign="center"
      >
        {t('Official Artist Account')} <Tooltip title={t('Official Artist Account', { ns: ['other'] })}
                      placement="top"><Audiotrack color="error"/></Tooltip>
      </Typography>
      <Typography>{t('content')}</Typography>

      <Typography color="primary" sx={{ mt: 3 }}><b>{t('tr1')}</b></Typography>
      <Typography>{t('tr2')}</Typography>
      </Stack>
    </BorderBox>
  )
}
