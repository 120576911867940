export const plLocaleSpec = {
  monthsShort: 'stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia'.split('_'),
  calendar: {
    lastDay: '[wczoraj o] H:mm',
    sameDay: '[dzisiaj o] H:mm',
    nextDay: '[jutro o] H:mm',
    lastWeek: 'D MMM [o] H:mm',
    nextWeek: 'D MMM [o] H:mm',
    sameElse: 'D MMM YYYY',
  },
}
