import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyCSuhiN72J3B2GLzVoF3eaI24KKH5cEEyk",
  authDomain: "talkie-e6a42.firebaseapp.com",
  databaseURL: "https://talkie-e6a42-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "talkie-e6a42",
  storageBucket: "talkie-e6a42.appspot.com",
  messagingSenderId: "189848909294",
  appId: "1:189848909294:web:032b214cd3411038460aff",
  measurementId: "G-NXS22V4K1B"
}

initializeApp(firebaseConfig)
